<template>
  <div
    v-if="confirmOpen"
    class="JoszakiConfirm flex items-center justify-center isolate overscroll-contain w-full h-full top-0 left-0 z-[5000] fixed"
  >
    <transition name="fade">
      <div
        class="JoszakiConfirmBackDrop absolute top-0 left-0 w-full h-full bg-white/90 z-0 blur-md"
        @click="close"
      />
    </transition>

    <transition name="slide-fade">
      <div
        class="JoszakiConfirmWindow z-1 w-full max-w-xl bg-white rounded-xl shadow-[0px_4px_20px_rgba(0,0,0,0.1)] max-h-[100vh-100px] overflow-y-auto flex flex-col relative isolate"
      >
        <div class="px-4 py-4 md:py-8">
          <JoszakiHeading :level="2">
            <template v-if="title">
              {{ title }}
            </template>
            <template v-else>
              {{ $t("common.areYouSure") }}
            </template>
          </JoszakiHeading>
        </div>
        <div class="flex justify-center items-center p-4 md:p-8 text-xl">
          <div class="flex-0 px-4">
            <IconComponent class="text-danger" icon="exclamation-triangle" />
          </div>
          {{ message }}
        </div>
        <div class="flex items-center justify-between p-4 md:p-8">
          <JoszakiButton class="text-xl" @click="close">
            {{ $t("common.cancel") }}
          </JoszakiButton>
          <JoszakiButton type="danger" class="text-xl" @click="confirm">
            {{ $t("common.accept") }}
          </JoszakiButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { JOSZAKI_CONFIRM_EVENT } from "~/plugins/joszaki";

export default {
  data() {
    return {
      confirmOpen: false,
      message: "asdadasdasasdasdas",
      callBack: null,
      onCancel: null,
      title: null,
    };
  },
  mounted() {
    this.$mitt.on(JOSZAKI_CONFIRM_EVENT, this.show);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_CONFIRM_EVENT, this.show);
  },
  methods: {
    show({ message, onConfirm, onCancel, title }) {
      console.debug("showing confirm", message, onCancel, onConfirm);
      if (!onConfirm) {
        throw new Error("missing onConfirm callback");
      }
      this.confirmOpen = true;
      this.message = message;
      this.title = title;
      this.callBack = onConfirm;
      this.onCancel = onCancel;
    },
    confirm() {
      this.callBack();
      this.close();
    },
    close() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.confirmOpen = false;
    },
  },
};
</script>
