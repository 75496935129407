<template>
  <div v-if="alertOpen" class="alert">
    <transition name="fade">
      <div class="backdrop" @click="close" />
    </transition>

    <transition name="slide-fade">
      <div class="alert-window">
        <div class="alert-header">
          <div :class="['header-icon', type]">
            <IconComponent :icon="icon" />
          </div>
          <div class="header-closer" @click="close">
            <IconComponent icon="times" />
          </div>
        </div>
        <div class="alert-message">
          {{ message }}
        </div>
        <div class="alert-footer">
          <JoszakiButton :type="type" :expanded="true" @click="close">
            {{ $t("common.accept") }}
          </JoszakiButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { JOSZAKI_ALERT_EVENT } from "~/plugins/joszaki";

export default {
  data() {
    return {
      alertOpen: false,
      message: "asdadasdasasdasdas",
      type: "info",
    };
  },
  computed: {
    icon() {
      if (!this.type) {
        return undefined;
      }
      switch (this.type) {
        case "success":
          return "check-circle";
        case "warning":
          return "exclamation-triangle";
        case "error":
        case "danger":
          return "times-circle";
        default:
          return "info-circle";
      }
    },
  },
  mounted() {
    this.$mitt.on(JOSZAKI_ALERT_EVENT, this.show);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_ALERT_EVENT, this.show);
  },
  methods: {
    show({ message, type }) {
      console.info("showing");
      if (
        ![
          undefined,
          null,
          "success",
          "info",
          "danger",
          "warning",
          "error",
        ].includes(type)
      ) {
        throw new Error(`received invalid type: ${type}`);
      }
      this.alertOpen = true;
      this.message = message;
      this.type = type ?? "info";
    },
    close() {
      this.alertOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;
.alert {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  isolation: isolate;
  overscroll-behavior: contain;
}

.alert-window {
  pointer-events: all;
  z-index: 1;
  width: 100%;
  max-width: 560px;

  background: white;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  max-height: calc(100vh - 100px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  position: relative;
  isolation: isolate;
}

.alert-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem;
  padding-bottom: 0;
  z-index: 2;
}

.header-icon {
  font-size: 2rem;

  &.info {
    color: global.$color-grayish-lightblue;
  }

  &.success {
    color: global.$color-green;
  }
}

.header-closer {
  &:hover {
    cursor: pointer;
    color: global.$lessSuperDarkBlue;
  }
}

.alert-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  font-size: 1.2rem;
}

.alert-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(white, 0.9);
  z-index: 0;
  backdrop-filter: blur(10px);
  pointer-events: all;
}
</style>
