<template>
  <div
    :class="[
      'JoszakiToast',
      'flex',
      'justify-center',
      'items-center',
      '!px-2',
      'sm:!px-4',
      '!py-1',
      'sm:!py-3',
      'rounded-md',
      '',
      'text-white',
      {
        'bg-info': type === 'info',
        'bg-success': type === 'success',
        'bg-warning': type === 'warning',
        'bg-error': type === 'error',
      },
    ]"
  >
    <IconComponent
      :icon="icon"
      class="mr-2 sm:mr-4 !text-base sm:!text-xl min-w-[20px]"
    />
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      default: "asdasd",
    },
  },
  computed: {
    icon() {
      if (!this.type) {
        return undefined;
      }
      switch (this.type) {
        case "success":
          return "check-circle";
        case "warning":
          return "exclamation-triangle";
        case "error":
        case "danger":
          return "times-circle";
        default:
          return "info-circle";
      }
    },
  },
};
</script>
